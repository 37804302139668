// Use a project component to contain
import React from 'react';
import Project from '../Project'


export default function Portfolio() {
    return (
        <div>
            <Project></Project>\
            <br/>
            <h3 className="text-light" style={{textAlign:"center"}}>Thanks for looking! :D</h3>
        </div>
    );
}
